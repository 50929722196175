export type Exploits = Record<
  string,
  {
    message: string;
    wallets: string[];
    actionLabel: string;
    link: string;
  }
>;

export const WALLET_SCREEN_ENDPOINT = 'https://api.tanukix.com/check-wallet';

export const ACTIVE_EXPLOITS: Exploits = {
  sorbet_finance: {
    message: `This address is reported to be at risk due to a Sorbet Finance hack. The owner is advised to secure their funds by revoking all approvals now.`,
    actionLabel: 'Find out more',
    link: 'https://twitter.com/gelatonetwork/status/1470071864068382723?s=20',
    wallets: [
      '0x0000000506063a51c6ce59906d8c40f7d7fe92a7',
      '0x000f4432a40560bbff1b581a8b7aded8dab80026',
      '0x00d6faa42d5d50e70a9ecd1489b2060c6b697f3e',
      '0x0388c96bbd7c7a9cb128386c90987af526db85d7',
      '0x03d6b5fe025ee2d3f3c08c9d74032259b1374ef2',
      '0x044a9c43e95aa9fd28eea25131a62b602d304f1f',
      '0x05e11de553a95955151d9815ef96ad0cd8daf6de',
      '0x067ef3c16f47cca6a3acb5942c6d4bc89ed0a8b1',
      '0x0698fa3b48313c5160619bdb970deb98e558ea75',
      '0x06d13d1562eb9da28c59e86e9bfc2a87d13afb01',
      '0x07e8503e2298569e39a41fdb8bf95bca24b02c4a',
      '0x089a2c44131b1eb30cc4fc226560163fcef2c288',
      '0x09528b6c6fc792eff0c70563c9b4c4c6e38da61d',
      '0x0b4adb43d3fcdf9239605cfcac08db509d2c3f3c',
      '0x0bbf7580e036ea5d69abe679cc90117eec2e3dc1',
      '0x0d8410643ae7a4d833c7219e5c6fadfa5ce912cd',
      '0x110ba807550eb898ce84330bec7ce8ede4dfb50e',
      '0x12c477fbfff136793c7a55b7d06d5e6d0a9f7314',
      '0x1404c00e08739bd020fdcc20de541edd1869c244',
      '0x143f59c155e2d932957c3f942611dba4216b070b',
      '0x1a08e51d571b873e271f56737a90d66a7e77056a',
      '0x1b30c84e244361a99be770acd57ace184983c83d',
      '0x1f90e6305fe02d9dd009a7696024b50f6cefbf4d',
      '0x2223770bb53c44a87de1425e95f20f63def86599',
      '0x239eec9ec218f71cef5cc14d88b142ed4ff44110',
      '0x27c45c933ce81328c7175e9a79ac0af848187c32',
      '0x2908a0379cbafe2e8e523f735717447579fc3c37',
      '0x2bb8ab3c2a9837de97a83c228a07e16928b4f07f',
      '0x2c492138498165c3f6ff94ab7d0b7ab6dd546ada',
      '0x2cd9e6e76725739208f5cc7b4b2d7629bf006c0d',
      '0x2de8e1bda7a4622aee5e928229d9a092b5361e69',
      '0x2f0642ab4a2fe7583416d54a6894c2c720c0e106',
      '0x3143bda87d3b7fdf4d19ccdb79c3020de57b1b73',
      '0x31adfbf4f653bcdc3a12c889fa721ee76605ffa8',
      '0x31fce7fd6931f7b5a6a756a219496f1eb0fdc54e',
      '0x33837822a540571d4591a2aeed4918725b840510',
      '0x33ebb62dc9ddbf6b8f3c0efdf5bccc2e7ac60211',
      '0x3465d93b84ed7557d42d84cb7c8999fc3db2113d',
      '0x36d960741d2ea0bfccfa23838828af528e38cd9a',
      '0x38535f27d5999da5b0280984ee64491cf3a4df80',
      '0x3943f78dcc1b07fbd429b3a27b141176350eeb26',
      '0x3c00a3fa2eef1afccf044af59ce105b8200272cd',
      '0x3d44f67b32454ebc1f579873edf480faa80cd953',
      '0x3ee505ba316879d246a8fd2b3d7ee63b51b44fab',
      '0x408329a24c6a3ae7dbab6e12237e921b1cb9450f',
      '0x4236cad967f018f4d87fd9c9aa0fb56e4f6fe9c2',
      '0x4286560cb4430270ab7d009f189224f070b1c861',
      '0x42d02f4cd48bdac116b7e3a4895f586777948fae',
      '0x460eebde20336aa615370f4b7b7a916e5656d4e6',
      '0x479c4a69b481449c4b92694eb32758cfc920735a',
      '0x48454ddf0ca25c7bdb5b419f63e9e85131e8a0e9',
      '0x48c4520f973b6d01ac233a831ab5d0aecb95190c',
      '0x4a0289cbf12318094b1919de497dd6fcc5088712',
      '0x4a40eb870dcf533d4dc097c3d87aafe9f64490a1',
      '0x4d4e1d6f54b0f6b94847e606207a2b6b67f5d81d',
      '0x5248102e1935eb8fc65bd5cdd3d775672666adf7',
      '0x578259a8ad60a9d49a86b036bcead82536a17f70',
      '0x58d797263f86ae8885c8757efe1a59bd179357c9',
      '0x5c5f29da63f873ee3028f56957b4e8798854a3d4',
      '0x61048acca5dc9559cc641acadaa51318c76e43e3',
      '0x611ac88f84d92b4eb07eadb6e4c98414515b2138',
      '0x62a89ad60eadda8187bb604594e6f91f2004db01',
      '0x62d51fa08b15411d9429133ae5f224abf3867729',
      '0x62fab0ffcc439c75a7d31f94f5b34be31f3e08e7',
      '0x63c0c0852c4c0933c7ea68524c2cbcf4b55845c4',
      '0x64b0010f6b90d0ae0bf2587ba47f2d3437487447',
      '0x661b9a30a09e5b983f19e85267144e98ff852a79',
      '0x6674d1c75384465a09855719a94e13b5b8591302',
      '0x66c47804e2eb462f27c6ef1dfab50753fb8e05d3',
      '0x66c9ba90158810b15e546a62fadfb566b607bbdd',
      '0x6b3de22ec227d168dfaee06f1fc629b49eb73e60',
      '0x6b56101f8070d5218e0a331d38e6a0f7e668302b',
      '0x6bb48ee6dc739db470e29e3dcf0c3b34e94277db',
      '0x6c1d01b3ae9494ed72666b413409fc8d0d46ece0',
      '0x6c4f06732de107dc4010cb7d8681e4f7269b394b',
      '0x7121fcee8d698c5597e71bc2a0ffdcd20a1baa86',
      '0x741d794ec94c34143d671d548b174bc237c4245e',
      '0x769a1ef6fbfa918d685ba02f3c2e45cfb902ebf7',
      '0x76d767c417c4e8ae1cd20436d81576c685bdc69e',
      '0x789063469df35f9aa569309731a1510dc9f0e8d5',
      '0x7afafe3c06f4d4864fe37e981bf73279b5f44218',
      '0x7c76a64ac61d1eeafe2b8af6f7f0a6a1890418f3',
      '0x7d390267b2d6a4ea28cdb610aeaca3ecebb191df',
      '0x801a8b12c6a6bd62dad7f2a8b3d8496c8d0857af',
      '0x80c33e7ca0d68ea5225abd24f571a94270f4204c',
      '0x827eb4260508f8ce4d97847c7cfa13f6521f0e06',
      '0x856b63349fb6c818ea7cd7305483ae0ef6956f6c',
      '0x86632848578699919df3944fba490a614ed762a3',
      '0x87afaa198117496bce792b6a5de3b33ba7cac73f',
      '0x881897b1fc551240ba6e2cabc7e59034af58428a',
      '0x887c3599c4826f7b3cde82003b894430f27d5b92',
      '0x8962228ceed52f581d5ec6cc7c2bb30c465285d9',
      '0x8aa25ef2c43f0afc214ed7263b6abd189dc18594',
      '0x8bda7405e13e9098ac8fb59d2be8c0d0cafbab5f',
      '0x8c6b10d42ff08e56133fca0dac75e1931b1fcc23',
      '0x8d493fa33a4ad78b17084e1422723c642e06cd54',
      '0x8d509a5ab4ac87eec0fd1610d3dbfc85b8bc0162',
      '0x8d88f384fb251c08805944f0c31e52a2277b530b',
      '0x8dc7bf3baf5c8ae60b14daeb2bcde95b32d94a84',
      '0x90931fad1c6fd50e16b45053521072a7070a5b1d',
      '0x9235edda7a84d917872a4278a5723ff2a34709db',
      '0x93100c6082321d7369d3d61ea5ffff0c697631d1',
      '0x97c469d23005d6f2633e55d157b8510132e4bf4a',
      '0x99148e35213a564f5fa2dcf37528cae1d457ed60',
      '0x9a16adef30aee2ae2e76572fcf3b548784ebda3b',
      '0x9a87a59040f86b5879cd21cf0b0c42a0c3e2a2ad',
      '0x9dbe078cb4c73069aa155fbbd28ca197755be734',
      '0x9e309c439c118804d9c2cf5eac4dfc62cc6f95c2',
      '0xa0a6dc36041fb386378458006fecbddd02555ddd',
      '0xa0b64f106dbdcd3b807b8dffdb5fefa87b3552b2',
      '0xa1bb9783775547d8879fe84db32e9c843c99328f',
      '0xa22668568becf98c08f9b190da43c3c48660bdb5',
      '0xa24ebe62c3e7167c87c6915081815a6b82d45a44',
      '0xa2b3c876a14c4055996360e209978d3ae2536d2e',
      '0xa41e643353b491c15de4f1b2b722cb48606102ed',
      '0xa658be407402612ec315fc1f62f410b339c0e658',
      '0xa7c394611a3602468ee76533693fd5405b9938d1',
      '0xa7edf97e7998de170f97d7d4317625cff7bbbcb9',
      '0xaa4d65d31c17372804af9660a7f34c983f0355f0',
      '0xac94634cefe9064ab4ed230c0553363ec4e894e9',
      '0xae11e327f4a40a05e25babadd2e049f9d813867f',
      '0xae6300452a0184f381d1458db92a384a678e28ed',
      '0xae81762fa6b0cea64520b3a746a5d2b418792a81',
      '0xaf9657e57e6b876dee2fb3f51cc05b5f1b852376',
      '0xb0824a58f3d26b6df4a037fc85b548f2a6eec096',
      '0xb3eeea75da2a63e3e3b903f9224959e6bf4b32b6',
      '0xb6d1f8a9dcaaebb72bb08d7bfeec47e5745c618d',
      '0xb7bfcdc3a2aa2af3fe653c9e8a19830977e1993c',
      '0xbab529eb7b11ed64fdf812e5a61f15f77b101988',
      '0xbc862a1d9a4f1abe62e2aa5ba58e74a4dc07dedc',
      '0xbe18f84532d8f7fb6d7919401c0096f3e257db8b',
      '0xc171deafb19be385c25fff8a235dd69b7f4b1db2',
      '0xc1bd49fcdf54aeb8e9c71f2811787f1298926b16',
      '0xc3d6f3c0fc331fce0ff1d012f58c70d37d67b181',
      '0xc50eaaee441566b4ef4f1e3270d19f24223cddf4',
      '0xc736e537c71cf1379dca3642d567ffe14fcb6051',
      '0xc82aed983d6cf7dce47c48aadf3f9eed14890ede',
      '0xc891b3462aedd0bcf391986e8cd1ae56705b8656',
      '0xce968c0fc101c4fb8e08eb5db73e7e169a2a3562',
      '0xcf47edc5454759f594a6d3a4b17457c36ea8e047',
      '0xd0b53c43d3e0b58909c38487aa0c3af7dfa2d8c0',
      '0xd14ab59a934a22b072f331453c205f85c168aae4',
      '0xd211a02a0adde56bb7f9700f49d4ba832adc7ddf',
      '0xd4ee113548dd66913dff6743e9b89e9fca7e4ead',
      '0xd56f305ebbd90b2c6be937a6efb8f0e02d57afa0',
      '0xd75fb2224fbf8f588fceeae5fb07346a4e0c43e3',
      '0xd78edfea5aa9fdf6ff4859e55082cbe9aeea8b27',
      '0xdaf02f3490ff5ad4762c0c019ab2510b5e456543',
      '0xdb5ac83c137321da29a59a7592232bc4ed461730',
      '0xdc87397165b4f0f6d586f1bf60fa37494a362a6a',
      '0xdda42f12b8b2ccc6717c053a2b772bad24b08cbd',
      '0xe1a24535d1dd36c2b1508e11138960c3950ab161',
      '0xe20c243d1435c6cf0e86c62fccf0885d54d84674',
      '0xe73b2b2c336dd7ef6e42f7a81725f2f3bb8e255a',
      '0xed3a954c0adfc8e3f85d92729c051ff320648e30',
      '0xee160154b02a2e404c6c13ff0b28ed76010cf07d',
      '0xee439ee079ac05d9d33a6926a16e0c820fb2713a',
      '0xf1b58ab117dbcd16be35f079e8ef9acaaa28cbdb',
      '0xf30ce3b3564d0d12b1b240013299c7f12fd5bd0f',
      '0xf37411d29cf36898e8d6e0a84bf4f28c0d57104b',
      '0xf7838c101dd142abff1b612bd4da21b21e43721f',
      '0xf91d4cf33a08abc1227cfd69fc92c2b1df9fd23c',
      '0xf97cb820bf3305e100ea3d02305905d14227e998',
      '0xf9ef36d19bda0cec0e073c385b1919431e9efe99',
      '0xfb1d7f6c700a053683f80447b387a891d5f76aac',
      '0xfdd3636caeb29b76fcb9472a93b73d36c7c3f5bf',
      '0xfff2f0277d7899e043741eebac6553b20e0639d5',
    ],
  },
};
